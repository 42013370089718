$(function() {
  $('.lazy').lazy({
    effect: "fadeIn",
    effectTime: 300,
    threshold: 0,
    afterLoad: function(element) {
      element.addClass('loaded');
    }
  });
});

$(function() {
  $('.lazy-header').lazy({
    effect: "fadeIn",
    effectTime: 600,
    threshold: 0,
    afterLoad: function(element) {
      element.addClass('loaded');
    }
  });
});

$('.togglePassword').on('click', function(){
  $(this).toggleClass('mdi-eye mdi-eye-off');
  let input = $($(this).data('toggle'));
  if (input.attr('type') == 'password'){
    input.attr('type', 'text');
  }
  else{
    input.attr('type', 'password');
  }
});

$('#year').on('change', function(){
  location.href = '/'+this.value;
});

$('#contact-form').on('submit', function(){
  // fake server response
  $('#contact-form').hide();
  $('#spinner').show();
  setTimeout(() => {
    $('#spinner').hide();
    $('#msg-sent').show();
  }, 1000);
});

// $('.guests-slider').slick({
//   infinite: true,
//   centerMode: false,
//   // prevArrow: '.player-slider-prev',
//   // nextArrow: '.player-slider-next',
//   slidesToShow: 3.6,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 1200,
//       settings: {
//         slidesToShow: 2,
//       }
//     },
//   ]
// });

function initSwipers() {
  var swiperNews = new Swiper(".latest-news .swiper", {
    spaceBetween: 30,
    effect: "creative",
    creativeEffect: {
      prev: {
        shadow: false,
        translate: [0, 0, -400],
      },
      next: {
        translate: ["100%", 0, 0],
      },
    },
    pagination: {
      el: ".latest-news .swiper-pagination",
      clickable: true,
    },
    speed: 1000,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    }
  });
  
  var swiperExhib = new Swiper(".card-exhib-holder-v2 .swiper", {
    slidesPerView: 3,
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: ".card-exhib-holder-v2 .custom-button-next",
      prevEl: ".card-exhib-holder-v2 .custom-button-prev",
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      545: {
        slidesPerView: 2,
      },
      1600: {
        slidesPerView: 3,
      },
    }
  });
  
  var swiperOff = new Swiper("#off_slider", {
    slidesPerView: 1,
    spaceBetween: 22,
    loop: true,
    pagination: {
      el: "#off_slider .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".off-navigation .swiper-button-next",
      prevEl: ".off-navigation .swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    }
  });
  
  var slideshow_home = new Swiper(".slideshow_home", {
    centeredSlides: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    speed: 1000,
    autoplay: {
      delay: 3500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".slideshow_home_title .swiper-pagination",
      clickable: true,
    },
  });
  
  var swiperGuest = new Swiper("#guest_slider", {
    slidesPerView: 2,
    spaceBetween: 12,
    loop: false,
    pagination: {
      el: "#guest_slider .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".ospiti-navigation .swiper-button-next",
      prevEl: ".ospiti-navigation .swiper-button-prev",
    },
    breakpoints: {
      445: {
        slidesPerView: 2,
        spaceBetween: 22,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 22,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      },
    }
  });

  var swiperJury = new Swiper("#jury_slider", {
    slidesPerView: 1,
    spaceBetween: 12,
    // loop: true,
    pagination: {
      el: "#jury_slider .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: "#jury-navigation-2022 .swiper-button-next",
      prevEl: "#jury-navigation-2022 .swiper-button-prev",
    },

    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 22,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 22,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 22,
      },
      1440: {
        slidesPerView: 5,
        spaceBetween: 22,
      },
    }
  });

  var swiperJury = new Swiper("#jury_slider_23", {
    slidesPerView: 1,
    spaceBetween: 12,
    // loop: true,
    pagination: {
      el: "#jury_slider_23 .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: "#jury-navigation-2023 .swiper-button-next",
      prevEl: "#jury-navigation-2023 .swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 22,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 22,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 22,
      },
      1440: {
        slidesPerView: 5,
        spaceBetween: 22,
      },
    }
  });

  var swiperNotizie = new Swiper("#notizie_slider", {
    slidesPerView: 1,
    spaceBetween: 22,
    loop: false,
    pagination: {
      el: "#notizie_slider .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".notizie-navigation .swiper-button-next",
      prevEl: ".notizie-navigation .swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: 4,
      }
    }
  });

  var swiperMostre = new Swiper("#mostre_slider", {
    slidesPerView: 1,
    spaceBetween: 22,
    loop: true,
    pagination: {
      el: "#mostre_slider .swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".mostre-navigation .swiper-button-next",
      prevEl: ".mostre-navigation .swiper-button-prev",
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
        pagination: false,
      }
    }
  });

  var swiper = new Swiper("#shop_slider", {
    slidesPerView: 1,
    spaceBetween: 22,
    loop: true,
    navigation: {
      nextEl: ".shop-home .swiper-button-next",
      prevEl: ".shop-home .swiper-button-prev",
    },
    breakpoints: {
      445: {
        slidesPerView: 2,
      },
      765: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
      1200: {
        slidesPerView: "auto",
      }
    }
  });
}

function checkMenu() {
  var scrolled = $(window).scrollTop();
  var preHeaderHeight = $('.pre-header').outerHeight();
  if (scrolled < preHeaderHeight) {
     $('.site-header').removeClass('menu-fixed');
  } else {
     $('.site-header').addClass('menu-fixed');
  }
  $('.menu-standby').css('top', preHeaderHeight);
  $('.menu-fixed').css('top', 0);
}

function shop_shop() {
 var windowWidth = $(window).width();

 if($('.bg-shop .h1').length) {
   var offsetLeftNum = $('.bg-shop .h1').offset().left + 4;
   var offsetLeft = offsetLeftNum.toString();
   var offsetTop = 'px +.45em';
   if ( windowWidth > 991 ) {
     var offsetTop = 'px .75em'
   }
   if ( windowWidth > 1200 ) {
     var offsetTop = 'px .0em'
   }
   if ( windowWidth > 1400 ) {
     var offsetTop = 'px 1em'
   }
   if ( windowWidth > 1600 ) {
     var offsetTop = 'px 1.6em'
   }
   var offsetLeftTop = offsetLeft+offsetTop;
   $('.bg-shop').addClass('ok').css('background-position', offsetLeftTop );
   //console.log(offsetLeftTop);
 
   $('.shop-slider-holder').css('right', -offsetLeft+16+'px');
   // $('#shop_slider .swiper-slide').css('width', $('.shop-home-container .slides-sizer').outerWidth() );
 }

}

function animate_counters() {
  $('.animate_counter').each(function() {
    var percentage = $(this).data('percentage');
    var delay = $(this).data('delay');
    $(this).find('.percentage').delay(delay).animate({width: percentage}, 500);
    $(this).find('.number').fadeIn();
  });
}

function store_store() {
  var windowWidth = $(window).width();

  if($('.bg-store .h1').length) {
    var offsetLeftNum = $('.bg-store .h1').offset().left + 4;
    var offsetLeft = offsetLeftNum.toString();
    var offsetTop = 'px +.45em';
    if ( windowWidth > 991 ) {
      var offsetTop = 'px .75em'
    }
    if ( windowWidth > 1200 ) {
      var offsetTop = 'px .0em'
    }
    if ( windowWidth > 1400 ) {
      var offsetTop = 'px 1em'
    }
    if ( windowWidth > 1600 ) {
      var offsetTop = 'px 1.6em'
    }
    var offsetLeftTop = offsetLeft+offsetTop;
    $('.bg-store').addClass('ok').css('background-position', offsetLeftTop );
    //console.log(offsetLeftTop);

    $('.shop-slider-holder').css('right', -offsetLeft+16+'px');
    // $('#shop_slider .swiper-slide').css('width', $('.shop-home-container .slides-sizer').outerWidth() );
  }

}

function distance_nav() {
 if($('body main').hasClass('distance-top')) {
   var h = $('.site-header').height();
   $('body main').css('padding-top', h + 'px');
 }
}

function secondary_menu() {
  //enable hamburger menu
  $('.hamburger-menu').on('click', ()=> {
    $('.hamburger-menu').toggleClass('secondary-menu-show');
    $('.site-header .secondary-menu').toggleClass('d-flex');
    $('.site-header .navbar-nav').toggleClass('text-green');
    if($('.site-header').hasClass('secondary-header-primary '))
      $('.site-header .navbar-brand').toggleClass('text-dark');
    else
      $('.site-header .navbar-brand').toggleClass('text-white');
    $('html').toggleClass('overflow-y-hidden');
  });
}

$(function () {
  checkMenu();
  // shop_shop();
  store_store();
  distance_nav();
  secondary_menu();
  initSwipers();
  $(".videobox").fitVids();
  animate_counters();
});

$(window).on("scroll", function () {
  checkMenu();
  // shop_shop();
  store_store();
});

$(window).on("resize", function () {
  checkMenu();
  // shop_shop();
  store_store();
  distance_nav();
});